import React from 'react';

interface Props {
  style?: any;
}

const Cardiogram: React.FC<Props> = ({ style }) => (
  <svg role="img" style={style} width="20" height="20" viewBox="0 0 512 512">
    <path d="m480.2,307.6l-47.1-.1c45.1-65.3 67.9-118.6 67.9-159 0-75.8-61.7-137.5-137.5-137.5-42.5,0-81.8,19.5-107.5,51.7-25.7-32.2-65-51.7-107.5-51.7-75.8,0-137.5,61.7-137.5,137.5 0,27.3 12.5,61.7 38.3,105.2 13,22 29.5,46.3 49.1,72.5h-66.8c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h98.6c56.3,69.6 113.1,127.3 113.6,127.9 4,4.1 9.3,6.1 14.6,6.1 5.2,0 10.3-1.9 14.3-5.8 2.3-2.2 57-55.8 112.6-124.5 6.2-7.7 12.2-15.2 17.9-22.6l76.7,.3c0,0 0.1,0 0.1,0 11.2,0 20.3-9.1 20.4-20.3 0.2-11.3-8.9-20.4-20.2-20.5zm-428.2-159.1c0-53.2 43.3-96.5 96.5-96.5 38.6,0 73.4,22.9 88.7,58.4 3.2,7.5 10.6,12.4 18.8,12.4s15.6-4.9 18.8-12.4c15.3-35.5 50.1-58.4 88.7-58.4 53.2,0 96.5,43.3 96.5,96.5 0,22.7-10.7,53.1-31.9,90.3-11.9,21-27.1,43.9-45.3,68.4l-90.8-.5-47.8-91c-3.9-7.5-12-11.7-20.4-10.8-8.4,1-15.3,7-17.4,15.2l-27.4,106.1h-28.9c-85-108-98.1-157.5-98.1-177.7zm206.7,302.7c-16.3-17.2-44.7-47.9-75.3-84.2h11.4c9.3,0 17.4-6.3 19.7-15.3l18.1-70.2 28.9,55c3.5,6.7 10.4,10.9 17.9,10.9l71.6,.4c-36.3,44.8-72.7,83.3-92.3,103.4z"/>
  </svg>
);

export default Cardiogram;

