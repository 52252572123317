import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

export const FeedIcon: React.FC<Props> = ({style}) => (
  <svg role="img" style={style} width="20" height="20" viewBox="0 0 512 512">
    <path d="M351.028,144.402H223.873c-11.207,0-20.291,9.083-20.291,20.291c0,11.207,9.083,20.291,20.291,20.291h127.155
      c11.214,0,20.291-9.083,20.291-20.291C371.318,153.485,362.235,144.402,351.028,144.402z"/>
    <path d="M399.725,242.473H223.873c-11.207,0-20.291,9.083-20.291,20.291c0,11.207,9.083,20.291,20.291,20.291h175.852
      c11.207,0,20.291-9.083,20.291-20.291C420.016,251.556,410.932,242.473,399.725,242.473z"/>
    <path d="M491.709,17.247H132.565c-11.207,0-20.291,9.083-20.291,20.291v191.408H76.428C34.284,228.946,0,263.237,0,305.374
      v112.951c0,42.144,34.284,76.428,76.428,76.428c3.449,0,6.845-0.23,10.166-0.676h334.775c49.969,0,90.631-40.656,90.631-90.631
      V37.538C512,26.33,502.917,17.247,491.709,17.247z M112.275,418.325c0,17.389-12.452,31.931-28.907,35.17h-6.939v0.676
      c-19.77,0-35.847-16.084-35.847-35.847V305.374c0-19.763,16.084-35.847,35.847-35.847h35.847V418.325z M471.419,403.445
      c0,27.129-22.915,50.05-50.05,50.05H144.273c5.485-10.531,8.583-22.496,8.583-35.17V57.828h318.563V403.445z"/>
    <path d="M351.028,334.464H223.873c-11.207,0-20.291,9.083-20.291,20.291c0,11.207,9.083,20.291,20.291,20.291h127.155
      c11.214,0,20.291-9.083,20.291-20.291C371.318,343.547,362.235,334.464,351.028,334.464z"/>
  </svg>
);
