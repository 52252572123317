import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

const Flame: React.FC<Props> = ({ style }) => (
  <svg role="img" style={style} width="20" height="20" viewBox="0 0 512 512">
    <path d="M446.906,299.769c-5.865-76.359-41.417-124.21-72.781-166.436C345.083,94.241,320,60.483,320,10.685
      c0-4-2.24-7.656-5.792-9.489c-3.563-1.844-7.844-1.542-11.083,0.812c-47.104,33.706-86.406,90.515-100.135,144.719
      c-9.531,37.737-10.792,80.161-10.969,108.18c-43.5-9.291-53.354-74.359-53.458-75.068c-0.49-3.375-2.552-6.312-5.552-7.916
      c-3.031-1.583-6.594-1.698-9.667-0.177c-2.281,1.104-55.99,28.394-59.115,137.355C64.01,312.726,64,316.362,64,319.997
      c0,105.857,86.135,191.987,192,191.987c0.146,0.01,0.302,0.031,0.427,0c0.042,0,0.083,0,0.135,0
      C362.167,511.681,448,425.667,448,319.997C448,314.674,446.906,299.769,446.906,299.769z M256,490.652
      c-35.292,0-64-30.581-64-68.172c0-1.281-0.01-2.573,0.083-4.156c0.427-15.853,3.438-26.675,6.74-33.873
      c6.188,13.291,17.25,25.509,35.219,25.509c5.896,0,10.667-4.771,10.667-10.666c0-15.186,0.313-32.706,4.094-48.518
      c3.365-14.02,11.406-28.936,21.594-40.893c4.531,15.52,13.365,28.081,21.99,40.341c12.344,17.54,25.104,35.675,27.344,66.6
      c0.135,1.833,0.271,3.677,0.271,5.656C320,460.07,291.292,490.652,256,490.652z"/>
  </svg>
);

export default Flame;

