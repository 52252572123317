import {v4 as uuidv4} from 'uuid';
import {Workout} from '../../reducers/workoutsReducer';
import {WORKOUT_VERSION} from '../../helpers/constants';
import Image from '../../assets/images/active-adult-aerobics-206573.jpg';

export const fullBodyPower: Workout = {
  id: 'full-body-power',
  name: 'Full Body Power',
  description: 'This workout will help build both strength and power by using short reps and compound movements.',
  imageUrl: Image,
  version: WORKOUT_VERSION,
  exerciseGroups: [
    {
      id: '81df4d60-3987-45fa-9504-f5242eb18544',
      name: 'Warm Up',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'stationary_bike',
          name: 'Stationary Bike',
          timerInSeconds: 300,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'lunges',
          name: 'Lunges',
          timerInSeconds: 60,
          restPeriodInSeconds: 30,
        },
      ],
    },
    {
      id: '7958db10-d0a1-474a-b8eb-e3d7ea92c1af',
      name: 'Exercises',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'back_squat',
          name: 'Back Squat',
          weightInKilos: 40,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'back_squat',
          name: 'Back Squat',
          weightInKilos: 40,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'back_squat',
          name: 'Back Squat',
          weightInKilos: 40,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'dead_lift',
          name: 'Dead Lift',
          weightInKilos: 80,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'dead_lift',
          name: 'Dead Lift',
          weightInKilos: 80,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'dead_lift',
          name: 'Dead Lift',
          weightInKilos: 80,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Ups',
          weightInKilos: 0,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Ups',
          weightInKilos: 0,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Ups',
          weightInKilos: 0,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 30,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 30,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 30,
          repsGoal: 5,
          repsAchieved: 5,
          autoIncrement: 2.5,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'push_ups',
          name: 'Push Ups',
          weightInKilos: 0,
          repsGoal: 20,
          repsAchieved: 20,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'push_ups',
          name: 'Push Ups',
          weightInKilos: 0,
          repsGoal: 20,
          repsAchieved: 20,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'push_ups',
          name: 'Push Ups',
          weightInKilos: 0,
          repsGoal: 20,
          repsAchieved: 20,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'ring_rows',
          name: 'Ring Rows',
          weightInKilos: 0,
          repsGoal: 12,
          repsAchieved: 12,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'ring_rows',
          name: 'Ring Rows',
          weightInKilos: 0,
          repsGoal: 12,
          repsAchieved: 12,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'ring_rows',
          name: 'Ring Rows',
          weightInKilos: 0,
          repsGoal: 12,
          repsAchieved: 12,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
      ],
    },
  ],
};
