import {v4 as uuidv4} from 'uuid';
import {Workout} from '../../reducers/workoutsReducer';
import {WORKOUT_VERSION} from '../../helpers/constants';
import Image from '../../assets/images/athlete-barbell-body-931321.jpg';

export const overheadStrength: Workout = {
  id: 'overhead-strength',
  name: 'Overhead Strength',
  description: 'This upper body workout will get you pushing and pulling overhead with superset pairs.',
  imageUrl: Image,
  version: WORKOUT_VERSION,
  exerciseGroups: [
    {
      id: '706601c7-d966-4f99-8172-77a1957fc1e3',
      name: 'Warm Up',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'sit_ups',
          name: 'Sit Ups',
          timerInSeconds: 60,
          restPeriodInSeconds: 30,
        },
        {
          id: uuidv4(),
          exerciseId: 'lunges',
          name: 'Lunges',
          timerInSeconds: 60,
          restPeriodInSeconds: 30,
        },
      ],
    },

    {
      id: '0ee1a57b-6dfc-43fb-95c9-f17597660a96',
      name: 'Press Superset 1',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 20,
          repsGoal: 12,
          repsAchieved: 12,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'concentration_curl',
          name: 'Concentration Curl',
          weightInKilos: 12.5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },

        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 30,
          repsGoal: 8,
          repsAchieved: 8,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'concentration_curl',
          name: 'Concentration Curl',
          weightInKilos: 12.5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },

        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 40,
          repsGoal: 4,
          repsAchieved: 4,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'concentration_curl',
          name: 'Concentration Curl',
          weightInKilos: 15,
          repsGoal: 8,
          repsAchieved: 8,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },

        {
          id: uuidv4(),
          exerciseId: 'overhead_press',
          name: 'Overhead Press',
          weightInKilos: 50,
          repsGoal: 1,
          repsAchieved: 1,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
        {
          id: uuidv4(),
          exerciseId: 'concentration_curl',
          name: 'Concentration Curl',
          weightInKilos: 12.5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 90,
        },
      ],
    },

    {
      name: 'Press Superset 2',
      id: '07e22d5d-75d8-4414-bcfc-4d879d9fee79',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'overhead_tricep_extension_dumbbell',
          name: 'Overhead Tricep Extension - Dumbbell',
          weightInKilos: 5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Up',
          weightInKilos: 0,
          repsGoal: 6,
          repsAchieved: 6,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_tricep_extension_dumbbell',
          name: 'Overhead Tricep Extension - Dumbbell',
          weightInKilos: 5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Up',
          weightInKilos: 0,
          repsGoal: 6,
          repsAchieved: 6,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_tricep_extension_dumbbell',
          name: 'Overhead Tricep Extension - Dumbbell',
          weightInKilos: 5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Up',
          weightInKilos: 0,
          repsGoal: 6,
          repsAchieved: 6,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_tricep_extension_dumbbell',
          name: 'Overhead Tricep Extension - Dumbbell',
          weightInKilos: 5,
          repsGoal: 10,
          repsAchieved: 10,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
        {
          id: uuidv4(),
          exerciseId: 'chin_up',
          name: 'Chin Up',
          weightInKilos: 0,
          repsGoal: 6,
          repsAchieved: 6,
          autoIncrement: 0,
          restPeriodInSeconds: 60,
        },
      ],
    },

    {
      name: 'Stretch',
      id: '61a1c4a9-9116-4ac8-b74b-25d19b11fb62',
      exercises: [
        {
          id: uuidv4(),
          exerciseId: 'right_arm_cross_body_stretch',
          name: 'Right Arm Cross Body',
          timerInSeconds: 60,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'left_arm_cross_body_stretch',
          name: 'Left Arm Cross Body',
          timerInSeconds: 60,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'right_leg_hamstring_stretch',
          name: 'Right Leg Hamstring stretch',
          timerInSeconds: 45,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'left_leg_hamstring_stretch',
          name: 'Left Leg Hamstring stretch',
          timerInSeconds: 45,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'right_glute_pigeon_pose_stretch',
          name: 'Right Glute Pigeon Pose',
          timerInSeconds: 60,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'left_glute_pigeon_pose_stretch',
          name: 'Left Glute Pigeon Pose',
          timerInSeconds: 60,
          restPeriodInSeconds: 0,
        },
        {
          id: uuidv4(),
          exerciseId: 'overhead_band_chest_stretch',
          name: 'Overhead Band Chest Stretch',
          timerInSeconds: 60,
          restPeriodInSeconds: 0,
        },
      ],
    },
  ],
};
